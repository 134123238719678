// The dropdown menu
.dropdown {
  // split warning dropdown toggle
  &.show {
    .btn-outline-warning,
    .btn-outline-light {
      &.dropdown-toggle {
        color: $white;
      }
    }
  }
  .btn:not(.btn-sm):not(.btn-lg),
  .btn:not(.btn-sm):not(.btn-lg).dropdown-toggle {
    padding: $dropdown-btn-padding-y $dropdown-btn-padding-x;
    &.btn-round {
      @include button-round-variant(2rem);
    }
  }

  .btn.dropdown-toggle.dropdown-toggle-split {
    padding: $dropdown-btn-padding-y $dropdown-btn-split-padding-x;
    border-left-color: rgba($white, 0.2) !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  [class*="btn-outline-"].dropdown-toggle.dropdown-toggle-split {
    border-left: 0 !important;

    &:active {
      color: $white;
    }
  }

  // dropdown menu
  .dropdown-menu {
    border: 1px solid rgba($pure-black, 0.05); // border opacity has been reduced to make it look same as vue's dropdown border [component page, list view page]
    border-radius: $dropdown-border-radius;
    transform: scale(1, 0);
    box-shadow: 0 5px 25px rgba($pure-black, 0.1); // this according to vue version
    // left: 5px;  // it creates issue in navbar - notification dropdown
    min-width: 8rem;
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: 1.2rem;
      width: 0.75rem;
      height: 0.75rem;
      display: block;
      background: $white;
      border-color: $white;
      transform: rotate(45deg) translate(-7px);
      border-top: 1px solid rgba($pure-black, 0.1);
      border-left: 1px solid rgba($pure-black, 0.1);
      z-index: 10;
      box-sizing: border-box;
    }

    &.dropdown-menu-right {
      &::before {
        right: 0.6rem;
        left: auto;
      }
    }

    &.dropdown-menu-left {
      &::before {
        background: #267659;
        border-color: #267659;
      }
    }

    .dropdown-header {
      padding: 0.714rem 1.428rem;
      margin-top: 0.714rem;
    }

    .dropdown-item {
      padding: 0.5rem 1rem;
      color: $body-color;
      width: auto;
      cursor: pointer;
      &:hover {
        background-color: $body-bg;
        color: $primary;
        svg,
        span {
          color: $primary;
        }
      }
      &.active {
        color: $white;
      }

      &:active {
        background-color: transparent;
        color: $dropdown-color;
      }

      &.disabled {
        color: $secondary;
      }
    }
  }

  .dropdown-menu-left {
    border: 1px solid rgba($pure-black, 0.05); // border opacity has been reduced to make it look same as vue's dropdown border [component page, list view page]
    border-radius: $dropdown-border-radius;
    transform: scale(1, 0);
    box-shadow: 0 5px 25px rgba($pure-black, 0.1); // this according to vue version
    // left: 5px;  // it creates issue in navbar - notification dropdown
    min-width: 8rem;
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: 1.2rem;
      width: 0.75rem;
      height: 0.75rem;
      display: block;
      background: #068F97;
      border-color: #068F97;
      transform: rotate(45deg) translate(-7px);
      border-top: 1px solid rgba($pure-black, 0.1);
      border-left: 1px solid rgba($pure-black, 0.1);
      z-index: 10;
      box-sizing: border-box;
    }

    .dropdown-header {
      padding: 0.714rem 1.428rem;
      margin-top: 0.714rem;
    }

    .dropdown-item {
      padding: 0.5rem 1rem;
      color: $body-color;
      width: auto;
      cursor: pointer;
      &:hover {
        background-color: $body-bg;
        color: $primary;
        svg,
        span {
          color: $primary;
        }
      }
      &.active {
        color: $white;
      }

      &:active {
        background-color: transparent;
        color: $dropdown-color;
      }

      &.disabled {
        color: $secondary;
      }
    }
  }

  // If don't won't dropdown/up arrow
  &.no-arrow {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }
}
// same padding for diffrent directions
.dropdown,
.dropup,
.dropright,
.dropleft {
  position: relative;
  .dropdown-menu {
    .dropdown-item {
      padding: 0.714rem 1.428rem;
    }
  }
  .dropdown-toggle {
    &:after,
    &:before {
      display: none;
    }
  }
}
.show {
  > .dropdown-menu {
    transform: scale(1, 1);
    opacity: 1;
    display: block;
  }
}

.dropdown-toggle {
  // changed icon caret

  &::after {
    // border: none !important;
    // font-family: "feather";
    // content: "\e842" !important;
    // position: relative;
    // top: 1px;
    // right: 0px;
    // left: 0.714rem;
    // padding: 0;
    // // left: 1.4rem;
    // margin: 0;
    // vertical-align: 0;
    display: none;
  }
  svg {
    position: relative;
    left: 10px;
  }
  &.dropdown-toggle-split {
    border-left: 1px solid rgba($white, 0.2);
    svg {
      left: 0;
    }
  }

  // hide default arrow to show custom icon DD
  &.nav-hide-arrow {
    &::after {
      display: none;
    }
  }

  // Prevent the focus on the dropdown toggle when closing dropdowns
  &:focus {
    outline: 0;
  }
}

// Horizontal layout navigation menu dropdown arrow
.navbar-horizontal {
  .dropdown-submenu {
    .dropdown-toggle::after {
      top: -9px !important;
      margin-top: 1.25rem;
      right: 1px;
    }
  }
}

// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown menu with the parent.
/* .dropdown-menu-right {
  left: auto !important; // Input group with dropdown issue for dropdown-menu-right
  right: 0;
} */

// Color theme active & hover
.btn-secondary ~ .dropdown-menu {
  .dropdown-item {
    &.active,
    &:hover {
      background-color: darken($white, 20%);
    }
  }
}

// DropUp
.dropup {
  position: relative;
  .btn,
  .btn.dropdown-toggle {
    padding: $dropdown-btn-padding-y $dropdown-btn-padding-x;
  }
  .btn.dropdown-toggle.dropdown-toggle-split {
    padding: $dropdown-btn-padding-y $dropdown-btn-split-padding-x;
    border-color: rgba($white, 0.2) !important;
  }
  .dropdown-toggle {
    // changed icon dropup caret
    &::after {
      content: "\e845" !important;
      vertical-align: 0.05rem;
    }
  }
  .dropdown-menu {
    &::before {
      content: "";
      position: absolute;
      bottom: -0.714rem;
      left: 1.214rem;
      width: 0.714rem;
      height: 0.714rem;
      display: block;
      background: $white;
      transform: rotate(45deg) translate(-7px);
      border-bottom: 1px solid rgba($pure-black, 0.1);
      border-right: 1px solid rgba($pure-black, 0.1);
      z-index: 10;
      box-sizing: border-box;
    }
    &.dropdown-menu-right {
      &::before {
        left: auto;
        right: 0.714rem;
      }
    }
  }
  .dropdown-submenu {
    .dropdown-menu {
      bottom: auto;
      top: 0;
    }
  }
}

// DropLeft
.dropleft {
  .btn,
  .btn.dropdown-toggle {
    padding: $dropdown-btn-padding-y $dropdown-btn-padding-x;
  }
  .btn.dropdown-toggle.dropdown-toggle-split {
    padding: $dropdown-btn-padding-y $dropdown-btn-split-padding-x;
  }
  .dropdown-toggle {
    // changed icon dropup caret
    // &::before {
    //   border: none !important;
    //   font-family: "feather";
    //   content: "\e843" !important;
    // }
    svg {
      left: -10px;
    }
  }
  .dropdown-menu {
    &::before {
      content: "";
      position: absolute;
      top: 1.285rem;
      right: 0;
      width: 0.714rem;
      height: 0.714rem;
      display: block;
      background: $white;
      transform: rotate(130deg) translate(-8px);
      border-top: 1px solid rgba($pure-black, 0.1);
      border-left: 1px solid rgba($pure-black, 0.1);
      z-index: 10;
      box-sizing: border-box;
    }
  }
}

// DropRight
.dropright {
  .btn,
  .btn.dropdown-toggle {
    padding: $dropdown-btn-padding-y $dropdown-btn-padding-x;
  }
  .btn.dropdown-toggle.dropdown-toggle-split {
    padding: $dropdown-btn-padding-y $dropdown-btn-split-padding-x;
  }
  .dropdown-toggle {
    // changed icon dropup caret
    &::after {
      border: none !important;
      font-family: "feather";
      content: "\e844" !important;
    }
  }
  .dropdown-menu {
    &::before {
      content: "";
      position: absolute;
      top: 1.285rem;
      left: -0.9rem;
      width: 0.714rem;
      height: 0.714rem;
      display: block;
      background: $white;
      transform: rotate(140deg) translate(-9px);
      border-bottom: 1px solid rgba($pure-black, 0.1);
      border-right: 1px solid rgba($pure-black, 0.1);
      z-index: 10;
      box-sizing: border-box;
    }
  }
}

// Dropdown icon
.dropdown,
.dropup {
  &.dropdown-icon {
    .dropdown-toggle {
      &:after {
        display: none;
      }
      svg {
        left: 0;
      }
    }
    .dropdown-menu {
      min-width: auto;
      .dropdown-item {
        padding: 0.5rem 1.1rem;
        cursor: pointer;
        &:hover {
          color: $white;
        }
      }
    }
  }
}

// for white button
.btn.btn-white ~ .dropdown-menu {
  .dropdown-item:not(.acitve):hover {
    color: $body-color !important;
  }
  .dropdown-item.active {
    color: $body-color;
  }
}
