.primary-color {
  color: #068f97 !important;
}
.underline {
  border-bottom: 1px solid gainsboro;
}
.sarabun-font {
  font-family: 'Sarabun' !important;
}
.f-14 {
  font-size: 14px;
}
.f-18 {
  font-size: 18px;
}
.rdt_TableCol div div {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.custombtinmodal {
  background-color: #068f97 !important;
}
.custombtCancel {
  border-color: #ff9630 !important;
  color: #ff9630 !important;
  background-color: transparent !important;
  border: 1px solid #ff9630 !important;
  &:hover {
    border-color: #ff9630 !important;
    border: 1px solid #ff9630 !important;
    color: #ff9630 !important;
    background-color: rgba(233, 236, 239, 0.5) !important;
    box-shadow: 0 8px 25px -8px #fff !important;
  }
  &:active {
    background: #ff9630 !important;
    color: #fff !important;
  }
}
.dotActive {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background: #267659;
}
.dotunActive {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background: #ea5455;
}
.pPadding4px {
  padding-left: 4px !important;
}
.valid-selectbox-Custom {
  border: 1px solid #ea5455;
  border-radius: 6px;
}
.custom-margin {
  margin: auto 3px;
}

// menu
.media-heading {
  position: relative;
  text-overflow: ellipsis;
  white-space: unset;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
